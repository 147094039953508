import { ASSETS } from '@/assets';
import { type BaseData, GRANT_TYPE, STORAGE_TOKEN_KEY, request } from '@/utils';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function () {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  async function onFinish(passwordModel: Record<string, any>) {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const info = await request<BaseData<{ accessToken?: string }>>({
        url: '/auth/oauth/token',
        method: 'POST',
        data: {
          grantType: GRANT_TYPE,
          passwordModel,
        },
      });
      localStorage.setItem(STORAGE_TOKEN_KEY, info.data?.accessToken);
      navigate('/');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div
        style={{ background: `url(${ASSETS.LOGIN_BG})`, backgroundSize: '100% 100%' }}
        className={'bg-no-repeat w-screen h-screen relative'}
      >
        <img
          className={'w-[380px] h-15 absolute left-15 top-15'}
          src={ASSETS.COMMON.LOGO}
          alt=""
        />
        <div className={'flex justify-end items-center absolute top-0 right-[380px] h-screen'}>
          <div className={'w-[440px] bg-white rounded-2 px-10 py-15'}>
            <div className={'text-40 text-primary h-[50px] font-500'}>您好！</div>
            <div className={'text-16 text-primary mt-2 mb-10'}>欢迎进入聘播播运营平台管理系统</div>
            <Form
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入账户' }]}
              >
                <Input
                  className={'h-12'}
                  size={'large'}
                  placeholder={'账户'}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password
                  className={'h-12'}
                  size={'large'}
                  placeholder={'密码'}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  block={true}
                  size={'large'}
                  type="primary"
                  className={'mt-10 h-12'}
                  htmlType="submit"
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
